<template>
    <div>
        <h2>Incluir titulo</h2>
        <v-alert v-show="mensagem" outlined type="error" border="left">
            {{ mensagem }}
        </v-alert>
        <detalhe-titulo
            :titulo="titulo"
            :historico="historico_titulo"
            :showActionSalvar="showActionSalvar"
            @actionSalvar="salvar()"
        ></detalhe-titulo>
    </div>
</template>
<script>
import DetalheTitulo from '@/components/DetalheTitulo';
import Titulo from '@/domains/Titulo';

export default {
    name: 'ConsultaTotulo',
    components: {
        DetalheTitulo
    },
    data() {
        return {
            titulo: new Titulo(),
            historico_titulo: [],
            showActionSalvar: true,
            mensagem: null
        };
    },
    methods: {
        salvar() {
            console.log(this.titulo);
        }
    }
};
</script>
